// LICENSE_CODE ABCFIT
let E = {};

const firebase = {
  apiKey: 'AIzaSyAwIQlS9po3A-5-jKc2mqqEInTvAjaz1ik',
  authDomain: 'abcfit-1382e.firebaseapp.com',
  projectId: 'abcfit-1382e',
  storageBucket: 'abcfit-1382e.appspot.com',
  messagingSenderId: '345532818285',
  appId: '1:345532818285:web:d3b68482c5e581d069f064',
  measurementId: 'G-GPELM0LY78',
};

E.prod = {
  auth0: {
    domain: 'abcfit.us.auth0.com',
    clientId: 'C6EjMbbweio5EMu3cFWsEa7sGcYb8Hwf',
    audience: 'YOUR_API_IDENTIFIER',
  },
  firebase,
};
E.dev = {
  auth0: {
    domain: 'abcfit-dev.us.auth0.com',
    clientId: 'YWEwhYyXXnY18rzkgI51TP4GQIDGqILq',
    audience: 'YOUR_API_IDENTIFIER',
  },
  firebase,
};

export default E;
