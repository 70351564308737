import React, { useRef, Suspense, useCallback, useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
const lazy_import = () => import('./lake');
const Lake = React.lazy(lazy_import);

const progress_labels = ['Loading...', 'Please wait...', `It's worth it`];

const Loading = React.memo(() => {
  useEffect(() => {
    const targets = document.querySelectorAll('h1');
    targets.forEach(neon);
  }, []);

  const [progress, set_progress] = useState(10);
  const [progress_cycle, set_progress_cycle] = useState(0);
  useInterval(() => {
    if (progress >= 100 && progress_cycle < progress_labels.length-1){
      set_progress(0);
      set_progress_cycle((curr) => Math.min(progress_labels.length - 1, curr + 1));
    } else {
      set_progress((curr) => curr + 10);
    }
  }, 1000);

  return (
    <div className="loading-container">
      <div className="logo-text">
        <h1>abc</h1>
        <h1>fit</h1>
      </div>
      <div className="spinner">
        <div style={{ color: 'white' }}>
          {progress_labels[progress_cycle]}
        </div>
        <progress className="loading-progress" value={progress} max="100" />
      </div>
    </div>
  );
});

const App = React.memo(() => {
  const [did_load, set_did_load] = useState(false);

  const on_load = useCallback(() => {
    set_did_load(true);
  }, []);

  return (
    <BrowserRouter>
      <div id="app">
        {!did_load && <Loading />}
        <Suspense fallback={null}>
          <Lake on_load={on_load} />
        </Suspense>
      </div>
    </BrowserRouter>
  );
});

const neon = (target) => {
  //const flicker_letter = (letter) =>
  //`<span style="animation: glow 1s linear both ">${letter}</span>`;
  const color_letter = (letter) =>
    `<span style="color: hsla(${Math.random() * 360}, 100%, 80%, 1);">${letter}</span>`;
  const flickerAndColorText = (text) => text.split('').map(color_letter).join('');
  target.innerHTML = flickerAndColorText(target.textContent);
};

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
export default App;
