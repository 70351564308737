import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Auth0Provider } from '@auth0/auth0-react';
import config_ext from './config';
import { history } from './history';

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

const providerConfig = {
  ...config_ext.auth0,
  // XXX colin: support other paths
  redirectUri: window.location.origin + '/',
  onRedirectCallback,
};

const root = ReactDOM.createRoot(
  document.getElementById('root')
)
root.render(
  <Auth0Provider {...providerConfig}>
    <App />
  </Auth0Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
