import config_ext from './config_ext.js';

let is_dev = process.env.NODE_ENV!=='production';
let config = config_ext[is_dev ? 'dev' : 'prod'];
let E = config;

let init = ()=>{
  const audience =
    config.auth0.audience && config.auth0.audience !== "YOUR_API_IDENTIFIER"
      ? config.auth0.audience
      : null;
  E.auth0 = {
    domain: config.auth0.domain,
    clientId: config.auth0.clientId,
    ...(audience ? { audience } : null),
  };
};
init();

export default E;
